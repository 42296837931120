<template>
  <div class="min-h-screen py-16 mevcut-container">
    <h1 class="text-3xl font-bold mb-8 text-center">{{ t('about_mevcut') }}</h1>

    <div class="max-w-3xl mx-auto text-center text-lg leading-relaxed mb-12 text-gray-900">
      <div v-if="aboutUs" v-html="aboutUs"></div>
    </div>

    <div class="flex justify-center mb-12">
      <LayoutFooterSocialIcon v-for="(item, index) in socialMedia" :key="index" :item="item" />
    </div>

    <div class="flex justify-center">
      <img
        src="/images/mevcut.webp"
        class="w-64 h-auto object-contain"
        :alt="t('mevcut')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { settings } = useSettings()
const { url } = useDomainHost()

const socialMedia = [
  { icon: 'mdi-email', url: `mailto:${settings.value?.email}`, colorClass: 'text-blue-500' },
  { icon: 'mdi-phone', url: `tel:${settings.value?.phone}`, colorClass: 'text-green-500' },
  { icon: 'fa: fa-brands fa-tiktok', url: settings.value?.tiktok?.trim(), colorClass: 'text-black' },
  { icon: 'mdi mdi-twitter', url: settings.value?.twitter?.trim(), colorClass: 'text-blue-400' },
  { icon: 'mdi mdi-facebook', url: settings.value?.facebook?.trim(), colorClass: 'text-blue-700' },
  { icon: 'mdi mdi-instagram', url: settings.value?.instagram?.trim(), colorClass: 'text-pink-500' }
]

const aboutUs = await useGroupSettings(12, 'about_us')

useHead({
  title: t('about_mevcut'),
  link: [useSeoCanonical(url + '/about-us')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('about_mevcut'), url + '/about-us')
    ])
  ]
})
</script>
